import React from "react";
import Footer from "../../components/Footer";

const Calendar = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "120vh",
          marginTop: "100px",
        }}
      >
        <iframe
          src="https://api.leadconnectorhq.com/widget/booking/BMHIOI6JwqtElW58qbig"
          style={{
            width: "90%",
            height: "100%",
            border: "none",
            overflow: "hidden",
          }}
          scrolling="no"
          id="BMHIOI6JwqtElW58qbig_1739388045621"
          title="Booking Calendar"
        />
      </div>
      <script
        src="https://link.msgsndr.com/js/form_embed.js"
        type="text/javascript"
      />
      <Footer />
    </>
  );
};

export default Calendar;
